import './storeLocationProfile.styles.scss';
import { useEffect, useState } from 'react';
import MyashopreeSidePage from 'components/Myashopree/side.page';
import config from "../../../config";
import random_cover_photo from 'assets/ashopree/edited_ashopree_logo1.jpg';
// import profile_photo from 'assets/images/estore/allcategories/category3.png';
// import post_photo from 'assets/images/estore/personalStore/prod7.png';
import user_icon from 'assets/icons/user_icon.png';
import verify from 'assets/ashopree/verify_badge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from 'utils/utils';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { LoaderVTwo } from 'components/loader/loader.component.version-two';




export const StoreLocationProfile = ({ title }) => {
    const apiUrl = config().baseUrl;
    const { id } = useParams();
    const [ error, setError ] = useState(null);
    const [locationProfile, setStoreLocationProfile] = useState([]);
    
    
    useEffect(() => {
      document.title = title;
      window.scrollTo(0, 0);
      
      axios.get(`${apiUrl}/ashopree/myashopree/store-location-profile/${id}`) 
    //   axios.get(`http://localhost:9090/api/v1/ashopree/myashopree/store-location-profile/${id}`)   
      .then(response => {
          setStoreLocationProfile(response.data.data[0]);
      }).catch(error => {
          setError('Error fetching a store profile using its id: ' + error.message);
      });
        
    }, [apiUrl, title, id, error]);

    // console.log(locationProfile);

    return (
        <div className="estore-container">

            <section className="store-profile">
                
                <section className="all-items">
                    <MyashopreeSidePage />

                    { Object.keys(locationProfile).length > 0 ? (
                        <main>
                            <div className="first">
                                <div className="both-pictures">
                                    <img className='background' src={random_cover_photo} alt="aShopree.com" />
                                    {/* <img className='profile' src={profile_photo} alt="user's profile name" /> */}
                                    <img className='profile' src={user_icon} alt={locationProfile.name} />
                                </div>

                                <div className="mobile-details">
                                    <div className="title-and-location">
                                        <h4> {locationProfile.name} <span> <img src={verify} alt="verified badge" /> </span> </h4>
                                        <p> {locationProfile.province} </p>
                                    </div>
                                    <div className="other-items">
                                        {/* <p> <button type='button' onClick={ () => copyToClipboard('item-to-copy') } > <FontAwesomeIcon icon={faLink} /> </button> <span id='item-to-copy'> www.https://paysprint.com </span> </p> */}
                                        <p> <button type='button' onClick={ () => copyToClipboard('item-to-copy') } > <FontAwesomeIcon icon={faLink} /> </button> <span id='item-to-copy'> {locationProfile.address} </span> </p>
                                        <button> {locationProfile.industry} </button>
                                        {/* <button> Ecommerce </button>
                                        <button> Tech </button> 
                                        <button> Sass </button> */}
                                    </div>
                                </div>

                                <div className="posts">
                                    <div className="head">
                                        <img src={user_icon} alt="profile icon" />
                                        <div>
                                            <h5> {locationProfile.name} </h5>
                                            <p> Founder </p>
                                        </div>
                                    </div>

                                    <div className="texts">
                                        <p> Login to account to set up Online Store. </p>
                                        <p> Click on Manage online store, select either Online Store for Goods or services and complete the forms. Provide the name of the store, upload good quality business logo, fill out the return and refund   policies, preview and save your work or publish your store. Click on Manage online store, select either Online Store for Goods or services and complete the forms. Provide the name of the store, upload good quality business logo, fill out the return and refund   policies, preview and save your work or publish your store. </p>
                                    </div>
                                </div>
                            </div>                                    

                            <div className="second">
                                <iframe src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11582.683237685028!2d-79.77424329338455!3d43.75170639916074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b52b7a5e6b8ad%3A0x1ebe29ba0f3e0e4d!2s2250%20Bovaird%20Dr%20E%20%23304%2C%20Brampton%2C%20ON%20L6R%200W3%2C%20Canada!5e0!3m2!1sen!2sid!4v1701869472878!5m2!1sen!2sid`} title={`304-2250 Bovaird Drive East, Brampton. ON L6R 0W3, Canada`} width="100%" height="100%" frameborder="0" style={{ border: '0' }} allowfullscreen="">
                                </iframe>
                            </div>
                        </main>
                    ) : (
                        <main style={{ flexDirection: 'column' }}>
                            <LoaderVTwo />
                        </main>
                    )}
                
                </section>

            </section>  

        </div>
    );

};

