export const RESPONSE_STATES = {
  none: "",
  loading: "loading",
  success: "success",
  error: "error",
};


// export const RECAPATCHA_KEYS = {
//   site_key: "6LfL6-opAAAAAEWHiWnTGqthfzHmoNw60j2yRj9G",
//   secret_key: "6LfL6-opAAAAAE9Z6Q6n1J75qlNuZQI5ZGjoHonX"
// }