import './terms.styles.scss';
import { useEffect } from 'react';
import { scrollToDataSection } from 'utils/utils';


export const TermsOfUse = ({ title }) => {

    useEffect(() => {
      document.title = title;
      window.scrollTo(0, 0);

    }, [title]);

    return (
        <div className="estore-container">

            <section className="termsandprivacy">
                <div className="contents">
                    <div className="details">
                        <h2> Terms Of Use </h2>

                        <div className="both-contents">
                            <div className="side">
                                <ol type='1'>
                                    <li onClick={ () => scrollToDataSection('term1')} > Account Terms </li>
                                    <li  onClick={ () => scrollToDataSection('term2')}> Account Activation
                                        <ol>
                                            <li> Store Owner </li>
                                            <li> Employee Accounts </li>
                                            <li> aShopree Payments and Checkout Accounts. </li>
                                            <li> My Branded PAY </li>
                                            <li> Domain Names </li>
                                        </ol>
                                    </li>
                                    <li  onClick={ () => scrollToDataSection('term3')}> aShopree Rights </li>
                                    <li onClick={() => scrollToDataSection('term4')}> Your Responsibilities </li>
                                    <li onClick={() => scrollToDataSection('term5')}> Payment of fees and taskes </li>
                                    <li onClick={() => scrollToDataSection('term6')}> Confidentiality </li>
                                    <li onClick={() => scrollToDataSection('term7')}> Limitation of Liability and Indemnification </li>
                                    <li onClick={() => scrollToDataSection('term8')}> Intellectual Property and Your Materials
                                        <ol>
                                            <li> Your Materials </li>    
                                            <li> aShopree Intellectual Property </li>    
                                            <li> aShopree Delivery </li>    
                                            <li> SMS Messaging </li>    
                                            <li> Theme Store </li>    
                                            <li> aShopree Email </li>    
                                            <li> My Online Store App </li>    
                                            <li> Third Party Services, Experts, and Experts Marketplace </li>    
                                            <li> Beta Services </li>    
                                        </ol>    
                                    </li>
                                    <li onClick={() => scrollToDataSection('term9')}> Feedback and Review </li>
                                    <li onClick={() => scrollToDataSection('term10')}> DMCA Notice and Takedown Procedure </li>
                                    <li onClick={() => scrollToDataSection('term11')}> Privacy and Data Protection </li>
                                    <li onClick={() => scrollToDataSection('term12')}> aShopree Contracting Party </li>
                                    <li onClick={() => scrollToDataSection('term13')}> Term and Termination </li>
                                    <li onClick={() => scrollToDataSection('term14')}> Modifications </li>
                                    <li onClick={() => scrollToDataSection('term15')}> General Conditions </li>
                                </ol>
                            </div>
                            <div className="main">
                                <div>
                                    <h5> Welcome to aShopree </h5>
                                    <p> By signing up for a aShopree Account (as defined in Section 1) or by using any aShopree Services (as defined below), you are agreeing to be bound by the following terms and conditions (the “Terms of Service”). </p>
                                    <p> As used in these Terms of Service, “we”, “us”, “our” and “Ashopree” means the applicable aShopree Contracting Party (as defined in Section 13 below), and “you” means the aShopree User (if registering for or using a aShopree Service as an individual), or the business employing the aShopree User (if registering for or using a aShopree Service as a business) and any of its affiliates. </p>
                                    <p> aShopree provides a flexible all-in-one online platform that offers Online store, virtual store front and virtual payments services that enables merchants to unify their commerce activities. Among other features, this platform includes a range of tools for merchants to customize online stores, sell in multiple places (including web, mobile, social media, other online platforms, and other online locations (“Online Services”) and manage products, inventory, payments, fulfillment, Delivery, business operations, marketing and advertising, and engage with existing and potential customers. Any such service or services offered by aShopree are referred to in these Terms of Services as the “Service(s)”. Any new features or tools which are added to the current Services will also be subject to the Terms of Service. You can review the current version of the Terms of Service at any time at https://ashopree.com/legal/terms. </p>
                                    <p> You must read, agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of Service, including Ashopree’s Acceptable Use Policy (“AUP”) and Privacy Policy, and, if applicable, the Supplementary Terms of Service for E.U. Merchants ("EU Terms"), the aShopree API License and Terms of Use (“API Terms”) and the aShopree Data Processing Addendum (“DPA”) before you may sign up for a aShopree Account or use any aShopree Service. Additionally, if you offer goods or services in relation to COVID-19, you must read, acknowledge, and agree to the Rules of Engagement for Sale of COVID-19 Related Products. </p>
                                    <p id='term1'> Please read the Terms of Service, including any document referred to in these Terms of Service, for the complete understanding of your legal requirements. By using aShopree or any aShopree services, you are agreeing to these terms. Please check back for updates regularly. </p>
                                </div> 
                                <ol type='1'>
                                    <li>
                                        <h5> Account Terms </h5>
                                        <p> Which means You are responsible for your Account, the Materials you upload to the aShopree Service and the operation of your aShopree Store. If you violate Ashopree’s terms of service, we may cancel your service access. If we need to reach you, we will communicate via the Primary Email Address on file. </p>
                                        <p> To access and use the Services, you must register for a aShopree account (“Account”).  To complete your Account registration, you must provide us with your full legal name, business address, phone number, a valid email address, and any other information indicated as required. aShopree may reject your application for an Account, or cancel an existing Account, for any reason, at our sole discretion. </p>
                                        <p> You must be the older of: (i) 18 years, or (ii) at least the age of majority based on the jurisdiction where you reside and from which you use the Services to open an Account. </p>
                                        <p> You confirm that you are receiving any Services provided by aShopree for the purposes of carrying on a business activity and not for any personal, household or family purpose. </p>
                                        <p> You acknowledge that aShopree will use the email address you provide on opening an Account or as updated by you from time to time as the primary method for communication with you (“Primary Email Address”). You must monitor the Primary Email Address you provide to aShopree and your Primary Email Address must be capable of both sending and receiving messages. Your email communications with aShopree can only be authenticated if they come from your Primary Email Address. </p>
                                        <p> You are responsible for keeping your password secure. aShopree cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password. </p>
                                        <p> We may request additional security measures at any time and reserve the right to adjust these requirements at our discretion. </p>
                                        <p> Technical support in respect of the Services is only provided to aShopree Users. Questions about the Terms of Service should be sent to aShopree Support. </p>
                                        <p> You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Services, or access to the Services without the express written permission by aShopree. </p>
                                        <p> You agree not to work around, bypass, or circumvent any of the technical limitations of the Services, including to process orders outside Ashopree’s Checkout, use any tool to enable features or functionalities that are otherwise disabled in the Services, or decompile, disassemble or otherwise reverse engineer the Services. </p>
                                        <p> You agree not to access the Services or monitor any material or information from the Services using any robot, spider, scraper, or other automated means. </p>
                                        <p> You understand that your Materials may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of </p>
                                        <p id='term2'> connecting networks or devices. “Materials” means Your Trademarks, copyright content, any products or services you sell through the Services (including description and price), and any </p>
                                        <p> photos, images, videos, graphics, written content, audio files, code, information, or other data provided or made available by you or your affiliates to aShopree or its affiliates. </p>
                                    </li> 
                                    <li>
                                        <h5> Account Activation </h5>
                                        <p> Which means Only contact person can be the “Store Owner”, usually the person signing up for the aShopree Service. The Store Owner is responsible for the Account, is bound by these Terms of Service and is responsible for the actions of others accessing the Account. If you sign up on behalf of your employer, your employer is the Store Owner responsible for your Account.We automatically create certain accounts for you to accept payments. You are responsible for activating and deactivating these accounts and complying with their terms, which may be with various third parties, including but not limited to PayPal, Apple Pay, and Google Payment. Any domain you purchase through us will automatically renew unless you opt out. </p>
                                        <ol>
                                            <li>
                                                <h5> Store Owner </h5>
                                                <p> Subject to Section 2.1.2, the person signing up for the Service by opening an Account will be the contracting party (“Store Owner”) for the purposes of our Terms of Service and will be the person who is authorized to use any corresponding Account we may provide to the Store Owner in connection with the Service. You are responsible for ensuring that the name of the Store Owner (including the legal name of the company that owns the Store, if applicable) is clearly visible on the Store’s website. </p>
                                                <p> If you are signing up for the Services on behalf of your employer, your employer will be the Store Owner. If you are signing up for the Services on behalf of your employer, then you must use your employer-issued email address and you represent and warrant that you have the authority to bind your employer to our Terms of Service. </p>
                                                <p> Your aShopree Store can only be associated with one Store Owner. A Store Owner may have multiple aShopree Stores. You agree to use aShopree Checkout for your store. “Store” means the online store (whether hosted by aShopree or on a third-party website), or any storefront built on top of the Storefront API) or physical retail location(s) associated with the Account. </p>
                                            </li>
                                            <li>
                                                <h5> Employee Account </h5>
                                                <p> Based on your aShopree pricing plan, you may create one or more employee accounts (“Employee Accounts”) allowing other people to access the Account. Each Employee Account must include a full legal name and a valid email account. With Employee Accounts, the Store Owner can set permissions and let other people work in their Account while determining the level of access by employee Accounts to specific business information (for example, you can limit employee Account access to sales information on the Reports page or prevent Employee Accounts from changing general store settings). The Store Owner is responsible for: (a) ensuring its employees, agents and subcontractors, including via Employee  Accounts, comply with these Terms of Service; and (b) any breach of these Terms of Service by the Store Owner’s employees, agents or subcontractors. </p>
                                                <p> The Store Owner acknowledges and agrees that Store Owner will be responsible for the performance of all of its obligations under the Agreement, regardless of whether it sublicenses or subcontracts any such obligations to any third party, including but not limited to any affiliates or subsidiaries of Store Owner. </p>
                                                <p> The Store Owner and the users under Staff Accounts are each referred to as a “Ashopree User”. </p>
                                            </li>
                                            <li>
                                                <h5> aShopree Payments and Checkout Accounts. </h5>
                                                <p> Upon completion of sign up for the Service, aShopree will create a Checkout account for your online store. You acknowledge that aShopree Payments will be your default payments gateway(s) and that it is your sole responsibility as the Store Owner to monitor your account. </p> 
                                            </li>
                                            <li>
                                                <h5> My Branded Pay </h5>
                                                <p> Upon completion of sign up for the Service, you automatically have access to aShopree Payments which is powered by our payment partners, depending on the jurisdiction you reside, and the name of the payment partner may appear at the checkout on your checkout page. </p>
                                                <p> By using aShopree Store, you agree to be bound by the Payment provider sub-merchant Terms of Service, as they may be amended from time to time. Your continued use of aShopree constitutes your agreement to, and acceptance of, any amended sub-merchant terms of Service. If you do not agree to any changes to the Sub-merchant Terms of Service, it is your responsibility to request for cancellation of the aShopree account. </p>
                                            </li>
                                            <li>
                                                <h5> Domain Names </h5>
                                                <p id='term3'> Upon purchasing a domain name through aShopree, domain registration will be preset to automatically renew each year so long as your aShopree Account remains active. You acknowledge that it is your sole responsibility to deactivate the auto-renewal function should you choose to do so. </p>
                                            </li>
                                        </ol>
                                    </li> 
                                    <li>
                                        <h5> aShopree Rights </h5>
                                        <p> Which means aShopree has the right to control who we make our Services available to and we can modify them at any time. We also have the right to refuse or remove Materials from any part of the Services, including your Store. We may offer our services to your competitors, but we will never share your confidential information with them in doing so. In the event of an ownership dispute over a aShopree Account, we can freeze a aShopree Account or transfer it to the rightful owner, as determined by us. </p>
                                        <p> The Services have a range of features and functionalities. Not all Services or features will be available to all Merchants at all times, and we are under no obligation to make any Services or features available in any jurisdiction. Except where prohibited in these Terms of Service or by applicable law, we reserve the right to modify the Services or any part thereof for any reason, without notice and at any time. </p>
                                        <p> aShopree does not pre-screen Materials and it is in our sole discretion to refuse or remove any Materials from any part of the Services, including if we determine in our sole discretion that the goods or services that you offer through the Services, or the Materials uploaded or posted to the Services, violate our AUP or these Terms of Service. </p>
                                        <p> Verbal or written abuse of any kind (including threats of abuse or retribution) of any aShopree employee, member, or officer will result in immediate Account termination. </p>
                                        <p> We reserve the right to provide our Services to your competitors and make no promise of exclusivity. You further acknowledge and agree that aShopree employees and contractors may also be aShopree customers or merchants and that they may compete with you, although they may not use your Confidential Information (as defined in Section 6) in doing so. </p>
                                        <p> In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the credit card on file, or confirmation of your status as an employee of an entity. </p>
                                        <p id='term4'> aShopree reserves the right to determine, in our sole discretion, rightful Account ownership and transfer an Account to the rightful Store Owner. If we are unable to reasonably determine the rightful Store Owner, without prejudice to our other rights and remedies, aShopree reserves the right to temporarily suspend or disable an Account until resolution has been determined between the disputing parties. </p>
                                    </li>
                                    <li>
                                        <h5> YOUR RESPONSIBILITIES </h5>
                                        <p> Which means you are responsible for your aShopree Store, the goods or services you sell, and your relationship with your customers, not us. If you access the aShopree API, your use of the aShopree API is subject to the API Terms. </p>
                                        <p> You acknowledge and agree to provide public-facing contact information, a refund policy and order fulfilment timelines on your aShopree Store. </p>
                                        <p> You acknowledge and agree that the Services are not a marketplace, and any contract of sale made through the Services is directly between you and the customer. You are the seller of record for all items you sell through the Services. You are responsible for the creation and operation of your aShopree Store, your Materials, the goods and services that you may sell through the Services, and all aspects of the transactions between you and your customer(s). This includes, but is not limited to, authorizing the charge to the customer in respect of the customer’s purchase, refunds, returns, fulfilling any sales or customer service, fraudulent transactions, required legal disclosures, regulatory compliance, alleged or actual violation of applicable laws (including but not limited to consumer protection laws in any jurisdiction where you offer products or services for sale), or your breach of these Terms of Service. You represent and warrant that your Store, your Materials and the goods and services you sell through the Services will be true, accurate, and complete, and will not violate any applicable laws, regulations or rights of third parties. For the avoidance of doubt, aShopree will not be the seller or merchant or record and will have no responsibility for your Store or items sold to customers through the Services. </p> 
                                        <p> You are solely responsible for the goods or services that you may sell through the Services (including description, price, fees, tax that you calculate, defects, required legal disclosures, regulatory compliance, offers or promotional content), including compliance with any applicable laws or regulations. </p>
                                        <p> You may not use the aShopree Services for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws), the laws applicable to you in your customer’s jurisdiction, or the laws of Canada and the Province of Ontario. </p>
                                        <p> You will comply with all applicable laws, rules and regulations (including but not limited to obtaining and complying with the requirements of any license or permit that may be necessary to operate your store or that may be held by you) in your use of the Service and your performance of obligations under the Terms of Service. </p>
                                        <p> The API Terms govern your access to and use of the aShopree API (as defined therein). You are solely responsible for the activity that occurs using your API Credentials (as defined in the API Terms) and for keeping your API Credentials secure. </p>
                                        <p id='term5'> You agree to use aShopree Checkout for any sales associated with your online store. “Ashopree Checkout” means Ashopree’s checkout experience that allows Customers to enter their Delivery information and payment details after adding item(s) to their cart and before placing an order, including checkouts that occur through the aShopree Checkout API. </p>
                                    </li>
                                    <li>
                                        <h5> PAYMENT OF FEES AND TAXES </h5>
                                        <p> Which means the fees (including subscription) and taxes would be directly debited to your merchant wallet, including additional fees required for all stores. You will be billed for your Subscription Fees every 30 days. Any Transaction Fees or Additional Fees will be charged to your merchant wallet. If we are unable to process payment of Fees using your wallet, we may request you to top up your wallet or your plan is downgraded to a free plan. </p>
                                        <p> You will pay the Fees applicable to your subscription to the Service  (“Subscription Fees”) and any other applicable fees, including but not limited to applicable fees relating to the value of sales made through your Store when using all payment providers other than aShopree Payments (“Transaction Fees”), and any fees relating to your purchase or use of any products or services such as aShopree Payments, Delivery, apps, Themes, domain names, or Third Party Services (“Additional Fees”). Together, the Subscription Fees, Transaction Fees and the Additional Fees are referred to as the “Fees”. </p>
                                        <p> Subscription Fees are paid in advance and will be billed in 30 day intervals (each such date, a “Billing Date”). Transaction Fees and Additional Fees will be charged from time to time at Ashopree’s discretion. You will be charged on each Billing Date for all outstanding Fees that have not previously been charged. Fees will appear on an invoice, which will be sent to the Store Owner via the Primary Email Address provided. </p>
                                        <p> All Fees are exclusive of applicable federal, provincial, state, local or other governmental sales, goods and services (including Goods and Sales Tax under the Goods and Services Tax Act, Chapter 117A of Singapore), harmonized or other taxes, fees or charges now in force or enacted in the future (“Taxes”). </p>
                                        <p> You are responsible for all applicable Taxes that arise from or as a result of your subscription to or purchase of Ashopree’s products and services. To the extent that aShopree charges these Taxes, they are calculated using the tax rates that apply based on the billing address you provide to us. Such amounts are in addition to the Fees for such products and services and will be billed to your merchant wallet. If you are exempt from payment of such Taxes, you must provide us with evidence of your exemption, which in some jurisdictions includes an original certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax exemption will only apply from and after the date we receive evidence satisfactory to aShopree of your exemption. If you are not charged Taxes by aShopree, you are responsible for determining if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your jurisdiction. </p>
                                        <p id='term6'> For the avoidance of doubt, all sums payable by you to aShopree under these Terms of Service will be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes charged by aShopree to you and remitted to the appropriate tax authorities on your behalf, any deductions or withholdings that are required by law will be borne by you and paid separately to the relevant taxation authority. aShopree will be entitled to charge the full amount of Fees stipulated under these Terms of Service to your merchant wallet ignoring any such deduction or withholding that may be required. </p>
                                        <p> You are solely responsible for determining, collecting, withholding, reporting, and remitting applicable taxes, duties, fees, surcharges and additional charges that arise from or as a result of any sale on your aShopree Store or your use of the Services. The Services are not a marketplace. Any contract of sale made through the Services is directly between you and the customer. </p>
                                    </li>
                                    <li>
                                        <h5> CONFIDENTIALITY </h5>
                                        <p> Which means both you and aShopree agree to use the Confidential Information of the other only to perform the obligations in these Terms of Service. Confidential Information must be protected and respected. </p>
                                        <p> “Confidential Information” will include, but will not be limited to, any and all information associated with a party’s business and not publicly known, including specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans and marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary. </p>
                                        <p> Ashopree’s Confidential Information includes all information that you receive relating to us, or to the Services, that is not known to the general public including information related to our security program and practices. </p>
                                        <p id='term7'> Each party agrees to use the other party’s Confidential Information solely as necessary for performing its obligations under these Terms of Service and in accordance with any other obligations in  these Terms of Service including this Section 6. Each party agrees that it will take all reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary information, to prevent the duplication, disclosure or use of any such Confidential Information, other than (i) by or to its employees, agents and subcontractors who must have access to such Confidential Information to perform such party’s obligations hereunder, who each will treat such Confidential Information as provided herein, and who are each subject to obligations of confidentiality to such party that are at least as stringent as those contained herein; or (ii) as required by any law, regulation, or order of any court of proper jurisdiction over the parties and the subject matter contained in these Terms of Service, provided that, if legally permitted, the receiving party will give the disclosing party prompt written notice and use commercially reasonable efforts to ensure that such disclosure is accorded confidential treatment. Confidential Information will not include any information that the receiving party can prove: (A) was already in the public domain, or was already known by or in the possession of the receiving party, at the time of disclosure of such information; (B) is independently developed by the receiving party without use of or reference to the other party’s Confidential Information, and without breaching any provisions of these Terms of Service; or (C) is thereafter rightly obtained by the receiving party from a source other than the disclosing party without breaching any provision of these Terms of Service. </p>
                                    </li>
                                    <li>
                                        <h5> LIMITATION OF LIABILITY AND INDEMNIFICATION </h5>
                                        <p> Which means we are not responsible for damages or lawsuits that arise if you break the law, breach this agreement or go against the rights of a third party. The Service is provided on an “as is” and “as available” basis. We provide no warranties and our liability is limited in the event of errors or interruptions. </p> 
                                        <p> You expressly understand and agree that, to the extent permitted by applicable laws, aShopree and its suppliers will not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses arising out of or relating to the use of or inability to use the Service or  these Terms of Service (however arising, including negligence). </p>
                                        <p> You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, aShopree partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of (a) your breach of these Terms of Service or the documents it incorporates by reference (including the AUP); (b) or your violation of any law or the rights of a third party; or (c) any aspect of the transaction between you and your Customer, including but not limited to refunds, fraudulent transactions, alleged or actual violation of applicable laws (including but not limited to Federal and State consumer protection laws), or your breach of the Terms of Service. </p>
                                        <p> You will be responsible for any breach of the Terms of Service by your affiliates, agents or subcontractors and will be liable as if it were your own breach. </p> 
                                        <p> Your use of the Services is at your sole risk. The Services are provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory. </p>
                                        <p> aShopree does not warrant that the Services will be uninterrupted, timely, secure, or error-free. </p>
                                        <p id='term8'> aShopree does not warrant that the results that may be obtained from the use of the Services will be accurate or reliable. </p>
                                        <p> aShopree is not responsible for any of your tax obligations or liabilities related to the use of Ashopree’s Services. </p>
                                        <p> aShopree does not warrant that the quality of any products, services, information, or other materials purchased or obtained by you through the Services will meet your expectations, or that any errors in the Services will be corrected. </p>
                                    </li>
                                    <li>
                                        <h5> INTELLECTUAL PROPERTY AND YOUR MATERIALS </h5>
                                        <p> Which means anything you upload remains yours (if it was yours) and is your responsibility, but aShopree can use and publish the things you upload. Anything uploaded to aShopree remains the property and responsibility of its initial owner. However, aShopree will receive a license of materials published through our platform that we may use to operate and promote our Services. </p>
                                        <ol>
                                            <li>
                                                <h5> Your Materials </h5>
                                                <p> We do not claim ownership of the Materials you provide to aShopree; however, we do require a license to those Materials. You grant aShopree a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to host, use, distribute, expose, modify, run, copy, store, publicly perform, communicate to the public (including by telecommunication), broadcast, reproduce, make available, display, and translate, and create derivative works of any Materials provided by you in connection with the Services. </p>
                                                <p> We may use our rights under this license to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service. You represent, warrant, and agree that you have all necessary rights in the Materials to grant this license. You irrevocably waive any and all moral rights you may have in the Materials in favour of aShopree and agree that this waiver may be invoked by anyone who obtains rights in the materials through aShopree, including anyone to whom aShopree may transfer or grant (including by way of license or sublicense) any rights in the Materials. </p>
                                                <p> If you owned the Materials before providing them to aShopree then, despite uploading them to your aShopree Store they remain yours, subject to any rights or licenses granted in the Terms of Service or elsewhere. You can remove your aShopree Store at any time by deleting your Account. Removing your aShopree Store does not terminate any rights or licenses granted to the Materials that aShopree requires to exercise any rights or perform any obligations that arose during the Term. </p>
                                                <p> You agree that aShopree can, at any time, review and delete any or all of the Materials submitted to the Services, although aShopree is not obligated to do so. </p> 
                                                <p> You grant aShopree a non-exclusive, transferable, sub-licensable, royalty-free, worldwide right and license to use the names, trademarks, service marks and logos associated with your Store (“Your Trademarks”) to operate, provide, and promote the Services and to perform our obligations and exercise our rights under the Terms of Service. This license will survive any termination of the Terms of Service solely to the extent that aShopree requires the license to exercise any rights or perform any obligations that arose during the Term. </p>
                                            </li>
                                            <li>
                                                <h5> aShopree Intellectual Property </h5>
                                                <p> You agree that you may not use any trademarks, logos, or service marks of aShopree, whether registered or unregistered, including but not limited to the word mark aShopree, the word mark aShopree or “a” on wheel (“Ashopree Trademarks”) unless you are authorized to do so by aShopree  in writing. You agree not to use or adopt any marks that may be considered confusing with the aShopree Trademarks. You agree that any variations or misspellings of the aShopree Trademarks could be considered confusing with the aShopree Trademarks. </p>
                                                <p> You agree not to purchase, register, or use search engine or other pay-per-click keywords (such as Google Ads), trademarks, email addresses, social media names, or domain names (including without limitation top-level domains, sub-domains, and page URLs) that use or include aShopree or aShopree Trademarks or that use or include any terms that may be confusing with the aShopree Trademarks. </p>
                                                <p> You acknowledge and agree that the Terms of Service do not give you any right to implement aShopree patents. </p>
                                            </li>
                                            <li>
                                                <h5> aShopree Delivery </h5>
                                                <p> Which means if you use aShopree delivery, you must comply with the aShopree Delivery Terms of Service. You can purchase Delivery labels from delivery centre (“Ashopree Delivery”) for delivery of goods purchased from you from your aShopree Store. In addition to these Terms of Service, your access to and use of aShopree Delivery is subject to the aShopree Delivery Terms of Service, located at(a) https://www.ashopree.com/legal/terms-Delivery-us if you are Delivery from the United States; (b) https://www.ashopree.com/legal/terms-Delivery-ca, if you are Delivery from Canada; and (c) https://www.ashopree.com/legal/terms-Delivery-global, if you are Delivery from a location outside of Canada and the United States. </p>
                                            </li>
                                            <li>
                                                <h5> SMS Messaging </h5>
                                                <p> Which means when you use our SMS Services, you have to comply with our terms, the acceptable use policies of the SMS providers, and the law. </p>
                                                <p> The Services allow you to send certain communications to your customers by short message service (SMS) messaging (for example, sending order confirmation notifications via SMS) (the "SMS Services"). You will only use the SMS Services in compliance with these Terms of Service, any other applicable terms (including Twilio’s Acceptable Use Policy, as may be amended from time to time), and the laws of the jurisdiction from which you send messages, and in which your messages are received. </p>
                                            </li>
                                            <li>
                                                <h5> Theme Store </h5>
                                                <p> Which means you can purchase our Themes to use for one Store at a time. You are free to modify our Themes, but do not infringe upon the intellectual property rights of others, including the designer. For Theme-related problems, contact the designer. Note that Themes may disappear over time and are subject to change. </p>
                                                <p> You may establish the appearance of your aShopree Store with a design template from Ashopree’s Theme Store (a “Theme”). If you download a Theme, you are licensed to use it for a single Store only. You are free to transfer a Theme to a second one of your own Stores if you close your first Store. To initiate a transfer of a Theme to a second one of your Stores, please contact aShopree Support. You are not permitted to transfer or sell a Theme to any other person’s Store on aShopree or elsewhere. Multiple Stores require multiple downloads and each download is subject to the applicable fee. aShopree gives no assurance that a particular Theme will remain available for additional downloads. </p>
                                                <p> You may modify the Theme to suit your Store. aShopree may add or modify the footer in a Theme that refers to aShopree at its discretion. aShopree may modify the Theme where it contains, in our sole discretion, an element that violates the aShopree AUP or other provisions of the Terms of Service, even if you received the Theme in that condition. aShopree may modify the Theme to reflect technical changes and updates as required. </p>
                                                <p> The intellectual property rights of the Theme remain the property of the designer. If you exceed the rights granted by your purchase of a Theme, the designer may take legal action against you, and, without prejudice to our other rights or remedies, aShopree may take administrative action such as modifying your Store or closing your Store. </p>
                                                <p> Technical support for a Theme is the responsibility of the designer, and aShopree accepts no responsibility to provide such support. aShopree may be able to help you contact the designer. It is the responsibility of the user, and not aShopree, to ensure that the installation of a new theme does not overwrite or damage the current or preexisting theme, or UI, of the user. </p>
                                            </li>
                                            <li>
                                                <h5> aShopree Email </h5>
                                                <p> Which means by using the Email Services, you agree that aShopree may use certain tools to ensure the content of the emails sent through the service is safe and compliant with our policies. </p>
                                                <p> You may generate or send email from your Account using the aShopree email services (the “Email Services”). In addition to the terms applicable to the Services generally (including Ashopree’s Acceptable Use Policy, and Privacy Policy), the following terms apply to your access and use of the Email Services: </p>
                                                <p> aShopree employs certain controls to scan the content of emails you deliver using the Email Services prior to delivery (“Content Scanning”). Such Content Scanning is intended to limit spam, phishing, or other malicious content that contravenes these Terms of Service, or Ashopree’s Acceptable Use Policy (collectively, “Threats”). By using the Email Services, you explicitly grant aShopree the right to employ such Content Scanning. aShopree does not warrant that the Email Services will be free from Threats, and each aShopree merchant is responsible for all content generated by their respective Stores. </p>
                                                <p> BY GENERATING OR SENDING EMAIL THROUGH THE EMAIL SERVICES, YOU AGREE TO COMPLY WITH THE FOLLOWING REQUIREMENTS (THE “EMAIL SERVICES REQUIREMENTS”). aShopree, OR ITS THIRD PARTY PROVIDERS, MAY SUSPEND OR TERMINATE YOUR ACCESS TO AND USE OF THE EMAIL SERVICES IF YOU DO NOT COMPLY WITH THE EMAIL SERVICES REQUIREMENTS. </p>
                                                <p> Your use of the Email Services must comply with all applicable laws. Examples of applicable laws include laws relating to spam or unsolicited commercial email (“UCE”), privacy, security, obscenity, defamation, intellectual property, pornography, terrorism, homeland security, gambling, child protection, and other applicable laws. It is your responsibility to know and understand the laws applicable to your use of the Email Services and the emails you generate or send through the Email Services. </p>
                                                <p> Your use of the Email Services must comply with Ashopree’s Privacy Policy. It is your responsibility to read and understand the Privacy Policy applicable to your use of the Email Services and the emails you generate or send through the Email Services. </p>
                                                <p> You will use commercially reasonable efforts not to send sensitive personal data, including information regarding an individual’s medical or health condition, race or ethnic origin, political opinions, religious or philosophical beliefs, or other sensitive data (collectively, “Sensitive Data”) through the Email Services. </p>
                                                <p> It is your responsibility to read and understand your obligations in relation to Sensitive Data. </p>
                                                <p> Your use of the Email Services must follow all applicable guidelines established by aShopree. The guidelines below are examples of practices that may violate the Email Services Requirements when generating, or sending emails through the Email Services: using non-permission based email lists (i.e., lists in which each recipient has not explicitly granted permission to receive emails from you by affirmatively opting-in to receive those emails); using purchased or rented email lists; using third party email addresses, domain names, or mail servers without proper permission; sending emails to non-specific addresses (e.g., webmaster@domain.com or info@domain.com); sending emails that result in an unacceptable number of spam or UCE complaints (even if the emails themselves are not actually spam or UCE); failing to include a working “unsubscribe” link in each email that allows the recipient to remove themselves from your mailing list; failing to comply with any request from a recipient to be removed from your mailing list within 10 days of receipt of the request; failing to include in each email a link to the then-current privacy policy applicable to that email; disguising the origin or subject matter of any email or falsifying or manipulating the originating email address, subject line, headers, or transmission path information for any email; failing to include in each email your valid physical mailing address or a link to that information; or including “junk mail,” “chain letters,” “pyramid schemes,” incentives (e.g., coupons, discounts, awards, or other incentives) or other material in any email that encourages a recipient to forward the Email to another recipient. </p>
                                                <p> If you or a customer knows of or suspects any violations of the Email Services Requirements, please notify aShopree at abuse@ashopree.com. aShopree will determine compliance with the Email Services Requirements in its discretion. </p>
                                                <p> Ashopree’s Email Services utilize Third Party Providers, including SendGrid (a Twilio Company). Your use of the Email Services is subject to SendGrid’s Acceptable Use Policy as it may be amended by SendGrid from time to time. </p>
                                            </li>
                                            <li>
                                                <h5> My Online Store App </h5>
                                                <p> Which means my Online Store App is a sales channel that is only available to Eligible Merchants and will display Materials from your aShopree Store. If you do not wish for your Materials to be available in the Online Store App, it is your responsibility to manage or deactivate this Service. </p>
                                                <p> The Online Store App is a aShopree application that is available to Eligible Merchants (defined below) the “Online Store App”, more details here) to engage with customers that use the Online Store (each, a “My Online Store App User”). </p>
                                                <p> The Online Store App is deemed to be a part of the Services, and all terms applicable to the Services apply. Without limiting the generality of the preceding sentence, and subject to these Terms of Service and all applicable guidelines and policies, aShopree reserves the right to refuse a Merchant access to or use of all or part of the Online Store App for any reason and at any time without prior notice. Only those Merchants who meet all merchant requirements and do not sell any prohibited products, as outlined in the List of restricted goods and services (as may be amended from time to time), are eligible to access and use the Online Store Services (“Eligible Merchants”). </p>
                                                <p> We may display your Materials, including product or service listings, Your Trademarks and any other content, data, or information from your aShopree Store on the My Online Store App. While my online store app enables Online Store Users to shop natively in the My Online Store App, the transaction is completed on your aShopree Store. For clarity and avoidance of doubt, Section 7 (Limitation of Liability) and Section 8 (Intellectual Property) apply to the use of your Materials on the Online Store and your responsibility for your Materials and purchases made through your aShopree Store. </p>
                                                <p> Your Materials and your use of the Online Store must comply at all times with our Online Store Merchant Guidelines, these Terms of Service, the Additional Terms, all other applicable policies and guidelines, and applicable law. </p>
                                                <p> As in other areas of the Service, aShopree is not obligated to intervene in any dispute arising between you and your customers (including Online Store Users). </p>
                                                <p> On the Online Store App, content is ranked by factors including relevance to a search query or a user’s interests, image quality, domain quality and how frequently other users have engaged with the content. </p>
                                                <p> For more information about how we display content on the Online Store App, see our Online Store help documentation. </p>
                                                <p> If you do not wish for your Materials to be available in the Online Store App, it is your responsibility to remove the materials from this Service. If you have the Online Store merchant channel installed, please see instructions here. </p>
                                                <p> If you do not yet have the Online Store merchant channel installed on your aShopree Store, install it here. </p>
                                                <p> If the Online Store merchant channel is not currently supported in your country, please contact support to remove your Materials from the Online Store App. Note that if you remove your Materials from the Online Store App, Online Store Users who purchase from your aShopree Store will continue to be able to view, manage and track orders from your aShopree Store in the Online Store App. If you delete your aShopree Account, your Materials will be removed from the Online Store App. </p>
                                            </li>
                                            <li>
                                                <h5> Third Party Services, Experts, and Experts Marketplace </h5>
                                                <p> Which means we are not responsible for third party services, so use them at your own risk. If you choose to use Third Party Services on the aShopree platform, you consent to us sharing your data (and potentially the data of your customers) to those services. If you use Third Party Services you agree that we do not provide a warranty, so get advice beforehand. </p>
                                                <p> aShopree may from time to time recommend, provide you with access to, or enable third party software, applications (“Apps”), products, services or website links (collectively, “Third Party Services”) for your consideration or use, including via the aShopree App Store, or Experts Marketplace. Such Third-Party Services are made available only as a convenience, and your purchase, access or use of any such Third Party Services is solely between you and the applicable third party services provider (“Third Party Provider”). </p>
                                                <p> In addition to these Terms of Service, you also agree to be bound by the additional service-specific terms applicable to services you purchase from, or that are provided by, Third Party Providers. </p>
                                                <p> aShopree Experts is an online directory of independent third parties (“Experts”) that can help you build and operate your aShopree Store. </p>
                                                <p> You can engage and work with an Expert directly or through Experts Marketplace. Experts Marketplace provides you with recommendations on Experts that can assist you with different aspects of your Store. Using Experts Marketplace, you can find, hire, and communicate with Experts directly from your Account admin. </p>
                                                <p> Any use by you of Third Party Services offered through the Services, aShopree Experts, Experts Marketplace or Ashopree’s website is entirely at your own risk and discretion, and it is your responsibility to read the terms and conditions and/or privacy policies applicable to such Third Party Services before using them.  </p>
                                                <p> In some instances, aShopree may receive a revenue share from Third Party Providers that aShopree recommends to you or that you otherwise engage through your use of the Services, aShopree Experts, Experts Marketplace or Ashopree’s website. </p>
                                                <p> We do not provide any warranties or make representations to you with respect to Third Party Services. You acknowledge that aShopree has no control over Third Party Services and will not be responsible or liable to you or anyone else for such Third Party Services. The availability of Third Party Services on Ashopree’s websites, including the aShopree App Store or Experts Marketplace, or the integration or enabling of such Third Party Services with the Services does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or with aShopree. aShopree does not guarantee the availability of Third Party Services and you acknowledge that aShopree may disable access to any Third Party Services at any time in its sole discretion and without notice to you. aShopree is not responsible or liable to anyone for discontinuation or suspension of access to, or disablement of, any Third Party Service. aShopree strongly recommends that you seek specialist advice before using or relying on Third Party Services, to ensure they will meet your needs. In particular, tax calculators should be used for reference only and not as a substitute for independent tax advice, when assessing the correct tax rates you should charge to your customers. </p>
                                                <p> If you install or enable a Third Party Service for use with the Services, you grant us permission to allow the applicable Third Party Provider to access your data and other Materials and to take any other actions as required for the interoperation of the Third Party Service with the Services, and any exchange of data or other Materials or other interaction between you and the Third Party Provider is solely between you and such Third Party Provider. </p>
                                                <p> aShopree is not responsible for any disclosure, modification or deletion of your data or other Materials, or for any corresponding losses or damages you may suffer, as a result of access by a Third Party Service or a Third Party Provider to your data or other Materials. Google Maps is a Third Party Service that is used within the Services. Your use of the Services is subject to your acceptance of the Google Maps and Earth Enterprise Universal Acceptable Use Policy, Google Maps and Google Earth Additional Terms of Service and Google Privacy Policy as it may be amended by Google from time to time. </p>
                                                <p> You acknowledge and agree that: (i) by submitting a request for assistance or other information through Experts Marketplace, you consent to being contacted by one or more Experts at the Primary Email Address (or such other email address provided by you) as well as the applicable user email address; and (ii) aShopree will receive all email communications exchanged via Experts Marketplace or in any reply emails (each a “Reply”) that originate from Experts Marketplace (directly or indirectly) between yourself and Experts. </p>
                                                <p> You further agree that aShopree may share your contact details and the background information that you submit via the Experts Marketplace with Experts. Experts may require access to certain admin pages on your aShopree Store. You choose the pages that the Experts can access. </p>
                                                <p> The relationship between you and any Third Party Provider is strictly between you and such Third Party Provider, and aShopree is not obligated to intervene in any dispute arising between you and a Third Party Provider. </p>
                                                <p> Under no circumstances will aShopree be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from any Third Party Services or your contractual relationship with any Third Party Provider, including any Expert. These limitations will apply even if aShopree has been advised of the possibility of such damages. The foregoing limitations will apply to the fullest extent permitted by applicable law. </p>
                                                <p> You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, aShopree partners, officers, directors, agents, employees, and suppliers harmless from any claim or                               demand, including reasonable attorneys’ fees, arising out of your use of a Third Party Service or your relationship with a Third Party Provider. </p>
                                            </li>
                                            <li>
                                                <h5> Beta Services </h5>
                                                <p> Which means Beta Services may be offered from time to time. They are not a part of the regular Services, and they might have errors or change at any time. </p>
                                                <p> You use Beta Services at your own risk, and we are not responsible for any losses or harm that might come from using a Beta Service. You may be required to keep your participation in the Beta Services confidential. </p>
                                                <p> From time to time, aShopree may, in its sole discretion, invite you to use, on a trial basis, pre-release or beta features that are in development and not yet available to all merchants (“Beta Services”). Beta Services are not part of the Services, and Beta Services may be subject to additional terms and conditions, which aShopree will provide to you prior to your use of the Beta Services. Such Beta Services and all associated conversations and materials relating thereto will be considered aShopree Confidential Information and subject to the confidentiality provisions in this agreement. Without limiting the generality of the foregoing, you agree that you will not make any public statements or otherwise disclose your participation in the Beta Services without Ashopree’s prior written consent. </p> 
                                                <p id='term9'> aShopree makes no representations or warranties that the Beta Services will function. aShopree may discontinue the Beta Services at any time in its sole discretion. aShopree will have no liability for any harm or damage arising out of or in connection with a Beta Service. The Beta Services may not work in the same way as a final version. aShopree may change or not release a final or commercial version of a Beta Service in our sole discretion. </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <h5> Feeback and review </h5>
                                        <p> Which means we welcome customer feedback but are under no obligation to ensure that ideas and suggestions regarding our Services or the services of third parties remain confidential and we can use the feedback in any way we want. </p>
                                        <p id='term10'> aShopree welcomes any ideas and/or suggestions regarding improvements or additions to the Services. Under no circumstances will any disclosure of any idea, suggestion or related material or any review of the Services, Third Party Services or any Third Party Provider (collectively, “Feedback”) to aShopree be subject to any obligation of confidentiality or expectation of compensation. By submitting Feedback to aShopree (whether submitted directly to aShopree or posted on any aShopree hosted forum or page), you waive any and all rights in the Feedback and that aShopree is free to implement and use the Feedback if desired, as provided by you or as modified by aShopree, without obtaining permission or license from you or from any third party. Any reviews of a Third Party Service or Third Party Provider that you submit to aShopree must be accurate to the best of your knowledge, and must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable. aShopree reserves the right (but not the obligation) to remove or edit Feedback of Third Party Services or Third Party Providers, but does not regularly inspect posted Feedback. </p>
                                    </li>
                                    <li>
                                        <h5> DMCA NOTICE AND TAKEDOWN PROCEDURE </h5>
                                        <p> Which means aShopree respects intellectual property rights and you should too. If we receive a DMCA Notice, we may disable access or remove the allegedly infringing content from your Store. If you don’t think the claim is valid, you can send a counter notification. If you believe one of our merchants is infringing your intellectual property rights, you can send aShopree a DMCA Notice. We will expeditiously disable access or remove the content and notify the merchant. </p>
                                        <p id='term11'> aShopree supports the protection of intellectual property and asks aShopree merchants to do the same. It’s our policy to respond to all notices of alleged copyright infringement. If someone believes that one of our merchants is infringing their intellectual property rights, they can send a DMCA Notice to Ashopree’s designated agent using our form. Upon receiving a DMCA Notice, we may remove or disable access to the Materials claimed to be a copyright infringement. Once provided with a notice of takedown, the merchant can reply with a counter notification using our form if they object to the complaint. The original complainant has 14 business days after we receive a counter notification to seek a court order restraining the merchant from engaging in the infringing activity, otherwise we restore the material. Learn more at Reporting Intellectual Property Infringement. </p>
                                    </li>
                                    <li>
                                        <h5> PRIVACY AND DATA PROTECTION </h5>
                                        <p> Which means Ashopree’s use and collection of personal information is governed by our Privacy Policy. Ashopree’s use and collection of customer personal information is further governed by our Data Processing Addendum. </p>
                                        <p> aShopree is firmly committed to protecting the privacy of your personal information and the personal information of your customers. </p>
                                        <p> By using the Service, you acknowledge and agree that Ashopree’s collection, usage and disclosure of this personal information is governed by our Privacy Policy. </p>
                                        <p id='term12'> To the extent that aShopree processes personal information of your customers as a “data processor” or “service provider” under certain data privacy or protection laws, including the EU or UK General Data Protection Regulation and the California Consumer Privacy Act, Ashopree’s collection and use of personal information is also subject to our Data Processing Addendum. </p>
                                    </li>
                                    <li>
                                        <h5> aShopree CONTRACTING PARTY </h5>
                                        <p> The billing address of your Store: </p>
                                        <p> “Ashopree Contract” means aShopree Inc., a Canadian corporation, with offices located at: 304-2250 Bovaird Drive East, Brampton, ON L6R 3J5. </p>
                                        <p id='term13'> The parties irrevocably and unconditionally submit to the exclusive jurisdiction of the courts of the Province of Ontario with respect to any dispute or claim arising out of or in connection with the Terms of Service. The United Nations Convention on Contracts for the International Sale of Goods will not apply to these Terms of Service and is hereby expressly excluded. </p>
                                    </li>
                                    <li>
                                        <h5> Term And Termination </h5>
                                        <p> Which means to initiate a termination, you must contact Support. aShopree will respond with specific information regarding the termination process for your account. Once termination is confirmed, domains purchased through aShopree will no longer be automatically renewed. If you cancel in the middle of your billing cycle, you’ll have one last email invoice. We may terminate your account at any time. The term of these Terms of Service will begin on the date of  your completed registration for use of a Service and continue until terminated by us or by you, as provided below (the “Term”). </p>
                                        <p> You may cancel your Account and terminate the Terms of Service at any time by contacting aShopree Support and then following the specific instructions indicated to you in Ashopree’s response. </p>
                                        <p> Without limiting any other remedies, we may suspend or terminate your Account or the Terms of Service for any reason, without notice and at any time (unless otherwise required by law), including if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the use of the Services. Termination of the Terms of Service will be without prejudice to any rights or obligations which arose prior to the date of termination. </p>
                                        <p> Upon termination of the Services by either party for any reason: </p>
                                        <p> aShopree will cease providing you with the Services and you will no longer be able to access your Account; </p>
                                        <p> unless otherwise provided in the Terms of Service, you will not be entitled to any refunds of any Fees, pro rata or otherwise; </p>
                                        <p> any outstanding balance owed to aShopree for your use of the Services through the effective date of such termination will immediately become due and payable in full; and your aShopree Store will be taken offline. </p>
                                        <p id='term14'> If you purchased a domain name through aShopree, upon cancellation your domain will no longer be automatically renewed. Following termination, it will be your sole responsibility to handle all matters related to your domain with the domain provider. If there are any outstanding Fees owed by you at the date of termination of the Service, you will receive one final invoice via email. Once that invoice has been paid in full, you will not be charged again. </p>
                                    </li>
                                    <li>
                                        <h5> Modifications </h5>
                                        <p> Which means If we make a material change to the Terms of Service that adversely affects your rights under the Terms of Service or your use of our Services in a material way, we will notify you in advance (unless the change relates to legal requirements or to prevent abuse of our Services, among other things). </p>
                                        <p> We reserve the right, in our sole and absolute discretion, to update or change any portion of the Terms of Service at any time. We will provide you with reasonable advance notice of changes to the Terms of Service that materially adversely affect your use of the Services or your rights under the Terms of Service by sending an email to the Primary Email Address, providing notice through the aShopree administrative console, or by similar means. However, aShopree may make changes that materially adversely affect your use of the Services or your rights under the Terms of Service at any time and with immediate effect (i) for legal, regulatory, fraud and abuse prevention, or security reasons; or (ii) to restrict products or activities that we deem unsafe, inappropriate, or offensive. </p>
                                        <p> Unless we indicate otherwise in our notice (if applicable), any changes to the Terms of Service will be effective immediately upon posting of such updated terms at this location. Your continued access to or use of the Services after we provide such notice, if applicable, or after we post such updated terms, constitutes your acceptance of the changes and consent to be bound by the Terms of Service as amended. If you do not agree to the amended Terms of Service, you must stop accessing and using the Services. </p>
                                        <p id='term15'> aShopree may change the Fees for the Services from time-to-time. We will provide you with 30 days advanced notice prior to any changes in Fees by sending an email to the Primary Email Account, providing notice through the aShopree administrative console, or by similar means. aShopree will not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Services (or any part thereof). </p>
                                    </li>
                                    <li>
                                        <h5> General Conditions </h5>
                                        <p> The Terms of Service, including the documents it incorporates by reference, constitute the entire agreement between you and aShopree and govern your use of the Services and your Account, superseding any prior agreements between you and aShopree (including, but not limited to, any prior versions of the Terms of Service). </p>
                                        <p> The failure of aShopree to exercise or enforce any right or provision of the Terms of Service will not constitute a waiver of such right or provision. If any provision of the Terms of Service, including all terms and conditions and other documents it incorporates by reference, is held by a court of competent jurisdiction to be contrary to law, such provision will be changed and interpreted so as to best accomplish the objectives of the original provision to the fullest extent allowed by law, and the remaining provision of the Terms of Service will remain in full force and effect. </p>
                                        <p> Save for aShopree and its affiliates, you or anyone accessing aShopree Services pursuant to these Terms of Service, unless otherwise provided in these Terms of Service, no person or entity who is not a party to these Terms of Service will have any right to enforce any term of these Terms of Service, regardless of whether such person or entity has been identified by name, as a member of a class or as answering a particular description. For the avoidance of doubt, this will not affect the rights of any permitted assignee or transferee of these Terms. </p>
                                        <p> The Terms of Service will be governed by and interpreted in accordance with the laws of the Province of Ontario and the laws of Canada applicable therein, without regard to principles of conflicts of laws. </p>
                                        <p> The Terms of Service may be available in languages other than English. To the extent of any inconsistencies or conflicts between these English Terms of Service and Ashopree’s Terms of Service available in another language, the most current English version of the Terms of Service at https://www.ashopree.com/legal/aup will prevail. Any disputes arising out of these Terms of Service will be resolved in English unless otherwise determined by aShopree (acting in its sole discretion) or as required by applicable law. </p>
                                        <p> All the terms and provisions of the Terms of Service will be binding upon and inure to the benefit of the parties to the Terms of Service and to their respective heirs, successors, permitted assigns and legal representatives. aShopree will be permitted to assign these Terms of Service without notice to you or consent from you. You will have no right to assign or otherwise transfer the Terms of Service, or any of your rights or obligations hereunder, to any third party without Ashopree’s prior written consent, to be given or withheld in Ashopree’s sole discretion. </p>
                                        <p> If any provision, or portion of the provision, in these Terms of  Service is, for any reason, held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality or unenforceability will not affect any other provision (or the unaffected portion of the provision) of the Terms of Service, and the Terms of Service will be construed as if such invalid, illegal or unenforceable provision, or portion of the provision, had never been contained within the Terms of Service. </p>
                                    </li>

                                </ol>           

                            </div>
                        </div>
                    </div>                    
                </div>                
            </section>

        </div>
    );

};


