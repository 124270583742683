import { handleLoginOption, toggleLoginScreen } from "utils/utils";
import "./login.scss";
import { useEffect, useState } from "react";
import { useAuth } from "../../hook/AuthProvider";
import Swal from "sweetalert2";
import { RESPONSE_STATES } from "utils/constants";
import { Loader } from "components/loader/loader.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faMailBulk } from "@fortawesome/free-solid-svg-icons";
import paysprint from 'assets/updatedAshopree/paysprint.png';



export const Login = ({ title }) => {
  const auth = useAuth();

  useEffect(() => {
    document.title = title;
    window.scrollTo(0, 0);

  }, [title]);

  const [accountNumber, setAccountNumber] = useState("");
  const [transactionPin, settransactionPin] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [responseState, setResponseState] = useState(RESPONSE_STATES.none);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;


  const submitLogin = async () => {
    if (!accountNumber || !transactionPin) { 
        return setErrorMessage("All fields must be filled.");
    }

    if (accountNumber.length < 4) {
        return setErrorMessage("PaySprint Number cannot be less than 4 characters.");
    }

    if (transactionPin.length < 4) {
        return setErrorMessage("Transaction Pin cannot be less than 4 characters.");
    }

    if (!/^\d+$/.test(accountNumber)) {
        return setErrorMessage("PaySprint Number must contain only numbers.");
    }

    try {
        setResponseState(RESPONSE_STATES.loading);
        setErrorMessage("");

        const responseState = await auth.loginAction({ accountNumber, transactionPin });
        setResponseState(responseState);

        if (responseState === RESPONSE_STATES.error) {
            setErrorMessage("Invalid Credentials");
        }
    } catch (error) {
        setResponseState(RESPONSE_STATES.none);
        const errorMessage = error.response ? error.response.data.message : error.message;
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: errorMessage,
        });
    }
  };

  const submitLoginWithEmail = async () => {
    if (!email || !password) { 
        return setErrorMessage("All fields must be filled.");
    }

    if (!emailRegex.test(email)) {
        return setErrorMessage("Invalid Email entered.");
    }

    if (password.length < 8) {
        return setErrorMessage("Password cannot be less than 8 characters.");
    }

    try {
      setResponseState(RESPONSE_STATES.loading);
      setErrorMessage("");

      const responseState = await auth.loginActionWithEmail({ email, password });
      setResponseState(responseState);

      if (responseState === RESPONSE_STATES.error) {
          setErrorMessage("Invalid Credentials");
      }

    } catch (error) {
        setResponseState(RESPONSE_STATES.none);
        const errorMessage = error.response ? error.response.data.message : error.message;
        
        Swal.fire({ icon: 'error', title: 'Error', text: errorMessage, });
    }
};


  return (
    <div className="estore-container">
        
        <section className="login_page_for_individual current_screen">
          <div className="acct-type">
            <div className="personal" type="button" onClick={ () => toggleLoginScreen('personal-acct') }> <p> Buyer Account </p> </div>
            <div className="merchant" type="button" onClick={ () => toggleLoginScreen('merchant-acct') }> <p> Seller Account </p> </div>
          </div>

          {/* <div className="personal-acct-form">
            <div className="welcome">
              <h1> Welcome Back </h1>
              <p> Please provide your details to log in </p>
            </div>

            <form>
              <div className="requirements">
                <div>
                  <p> PaySprint Number <span> * </span> </p>
                  <input type="text" name="accountNumber" placeholder="123456789" required value={accountNumber} onChange={e => setAccountNumber(e.target.value)} />
                </div>
                <div>
                  <p> Transaction Pin <span> * </span> </p>
                  <input type="password" name="transactionPin" placeholder="****" autoComplete="" required value={transactionPin} onChange={e => settransactionPin(e.target.value)} />
                </div>
                
                {errorMessage &&  <em className="error">*{errorMessage}</em> }
              </div>

              <div className="other-details">
                <button type="button" onClick={() => submitLogin()}>
                  {responseState === RESPONSE_STATES.loading ? <Loader /> : "Proceed"}
                </button>
                <p> Don't have an account? <a href="/register"> Create Account </a> </p>
              </div>
            </form>
          </div> */}

          <section className="get-started">
            <div className="main">
              <div className="header">
  
                <div className="body">
                  <div className="head">
                    <h1> Sign in to your account </h1>
                    <p> Access all our products and services with one single account </p>
                  </div>

                  <div className="externals">
                    <button type="button" onClick={ () => handleLoginOption("emailSignIn", "paysprintSignin")}> <img src={paysprint} alt="paysprint_logo" /> <p> PaySprint </p> </button>
                    <button type="button" onClick={ () => handleLoginOption("paysprintSignin", "emailSignIn")} style={{ fontSize: '25px' }}> <FontAwesomeIcon icon={faMailBulk} /> <p> Use Email Address </p> </button>
                  </div>
                </div>
              </div>

              <form action="#" className="emailSignIn loginOption">
                <div className="requirements">
                  <p> <hr /> <span className="alt"> or sign in with email </span> </p>
                  <div className="info-session">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <p> Please proceed to login with your Email details </p>
                  </div>
                  <div>
                    <p> Email Address <span> * </span> </p>
                    <input type="email" name="email" placeholder="john.doe@example.com" required value={email} onChange={e => setEmail(e.target.value)} />
                  </div>
                  <div>
                    <p> Password <span> * </span> </p>
                    <input type="password" name="password" placeholder="********" autoComplete="" required value={password} onChange={e => setPassword(e.target.value)} />
                  </div>
                  
                  {errorMessage &&  <em className="error">*{errorMessage}</em> }
                </div>

                <div className="other-details">
                  <button type="button" onClick={() => submitLoginWithEmail()}>
                    {responseState === RESPONSE_STATES.loading ? <Loader /> : "Proceed"}
                  </button>
                  <p> Don't have an account? <a href="/register"> Create Account </a> </p>
                </div>
              </form>

              <form action="#" className="paysprintSignin">
                <div className="requirements">
                  <p> <hr /> <span className="alt"> sign in with <span style={{ color: '#4F0B92', textDecoration: 'underline' }}> PAYSPRINT </span> </span> </p>
                  <div className="info-session">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <p> Please proceed to login with your PaySprint details </p>
                  </div>
                  <div>
                    <p> PaySprint Number <span> * </span> </p>
                    <input type="text" name="accountNumber" placeholder="123456789" required value={accountNumber} onChange={e => setAccountNumber(e.target.value)} />
                  </div>
                  <div>
                    <p> Transaction Pin <span> * </span> </p>
                    <input type="password" name="transactionPin" placeholder="****" autoComplete="" required value={transactionPin} onChange={e => settransactionPin(e.target.value)} />
                  </div>

                  {errorMessage &&  <em className="error">*{errorMessage}</em> }
                </div>

                <div className="other-details">
                  <button type="button" onClick={() => submitLogin()}>
                    {responseState === RESPONSE_STATES.loading ? <Loader /> : "Proceed"}
                  </button>
                  <p> Don't have an account? <a href="/register"> Create Account </a> </p>
                </div>
              </form>
            </div>
          </section>

        </section>
        
        <section className="login_page_for_merchant">
          <div className="acct-type">
            <div className="personal" type="button" onClick={ () => toggleLoginScreen('personal-acct') }> <p> Buyer Account </p> </div>
            <div className="merchant" type="button" onClick={ () => toggleLoginScreen('merchant-acct') }> <p> Seller Account </p> </div>
          </div>

          <div className="merchant-acct-form">
            <div className="welcome">
              <h1> Welcome Back </h1>
              <p> Please provide your Merchant details to proceed </p>
            </div>

            <form>
              <div className="requirements">
                <div>
                  <p> PaySprint Merchant Number <span> * </span> </p>
                <input type="text" name="accountNumber" placeholder="123456789" required value={accountNumber} onChange={e => setAccountNumber(e.target.accountNumber)} />
                </div>
                <div>
                  <p> Transaction Pin <span> * </span> </p>
                <input type="password" name="transactionPin" placeholder="****" autoComplete="" required value={transactionPin} onChange={e => settransactionPin(e.target.transactionPin)} />
                </div>
              </div>

              <div className="other-details">
              <button type="button" onClick={() => submitLogin()}>
                  Proceed
                </button>
                <p> Don't have an account? <a href="/register"> Create Account </a> </p>
              </div>
            </form>
          </div>

        </section>

    </div>

  );

};
