import './rating.styles.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import config from "../../config";
import { notificationAlert } from 'utils/utils';
import { RESPONSE_STATES } from 'utils/constants';
import { Loader } from 'components/loader/loader.component';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'hook/AuthProvider';



export const Ratings = ({ title }) => {
    const auth = useAuth();
    const apiUrl = config().baseUrl;
    const [star_rating, setRating] = useState(4);
    const [hover, setHover] = useState(0);
    const [comment_title, setRatingTitle] = useState("");
    const [comment, setRatingContent] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [responseState, setResponseState] = useState(RESPONSE_STATES.none);
    const { productCode, productId } = useParams();
    const product_code = productCode;
    const product_id = productId;
    const user = useState(JSON.parse(auth.user));
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        const myself = user[0];
        const data = { 
            starRating: star_rating, 
            commentTitle: comment_title, 
            comment: comment, 
            productId: product_id,
            productCode: product_code,
            nameOfSender: myself.name, 
            emailOfSender: myself.email,
        };

        if (!star_rating || !comment_title || !comment) { 
            return setErrorMessage("All fields must be filled.");
        }
   
        if (!product_id || !product_code || !data) {
            notificationAlert('warning', 'Please try again', 'Please refresh this page or try going to the homepage and coming back so that your reviews about this product can count.');
            return setErrorMessage("Please refresh this page or try going to the homepage and coming back so that your reviews about this product can count.");
        }

        try {
            setResponseState(RESPONSE_STATES.loading);
            setErrorMessage("");
        
            const apiConfig = { method: 'POST', url: `${apiUrl}/ashopree/myashopree/product-rating`, data,
                headers: { Authorization: "Bearer base64:JFM+PJaWD/pBypX+NhXudDrAmianZdGYZ41qz4WhXL0=" }
            }

            const result = await axios(apiConfig);
            const resp  = result.data;
            // console.log(resp);     

            if (resp.status === 200) { 
                setResponseState(RESPONSE_STATES.success);
                notificationAlert('success', 'Successfully submitted', resp.message);
                navigate("/");
            } else {
                notificationAlert('error', 'Please try again', "Sorry, We could not complete your request at this moment kindly login to your aShopree account and try again :)");
                navigate(`/product-rating/${product_code}`);
                return RESPONSE_STATES.error;
            }

        } catch (error) {
            setResponseState(RESPONSE_STATES.none);
            const errorMessage = error.response ? error.response.data.message : error.message;
            
            notificationAlert('error', 'Error', errorMessage);
        }
        
    };


    useEffect(() => {
        document.title = title;
        window.scrollTo(0, 0);


    }, [title]);


    return (
        <div className="estore-container">

            <section className='ratings'>
                <form action="#" onSubmit={handleSubmit}>
                    <div className="head">   
                        <h1> Rate and write a review </h1>
                        <div>
                            {Array(5).fill(1).map((_, index) => (
                                <FontAwesomeIcon key={index} icon={faStar} onClick={() => setRating(index + 1)} onMouseEnter={() => setHover(index + 1)}
                                    onMouseLeave={() => setHover(star_rating)}
                                    style={{ color: (hover || star_rating) > index ? '#E59819' : '#d2d2d2', cursor: 'pointer' }}
                                />
                            ))}
                        </div>
                    </div>  
 
                    <div className="contents">  
                        <div className="title">
                            <h5> Review Title </h5>
                            <input name='comment_title' type="text" placeholder='Great Products/Services' required value={comment_title} onChange={e => setRatingTitle(e.target.value)} />
                        </div>

                        <div className="words">
                            <h5> Review Content </h5>
                            <textarea name="comment" id="" placeholder="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English." required value={comment} onChange={e => setRatingContent(e.target.value)}></textarea>
                        </div>
                    </div>

                    {errorMessage &&  <em className="error">*{errorMessage}</em> }

                    {responseState === RESPONSE_STATES.loading ? 
                        <button className='loader'> <Loader /> </button>
                    :  
                        <button type='submit'> Submit Review </button> 
                    }
                </form>
            </section>

        </div>
    );
};